import React from "react";
import config from ".config.js";
import integration from "assets/svg/integration.svg";
import campaign from "assets/svg/campaign.svg";
import transactionals from "assets/svg/transactional.svg";
import insight from "assets/svg/insight.svg";
import payment from "assets/svg/payment.svg";
import segmentation from "assets/svg/segmentation.svg";
import feature from "assets/svg/feature.svg";
import { Feature } from "elements/feature";
import styles from "./features.module.css";
import vector from "assets/svg/vector.svg";
import line from "assets/svg/line.svg";
import { CTAButton } from "elements/buttons";
import generalStyle from "app.module.css";

const features = [
  {
    icon: transactionals,
    tagline: "Transactional Emails",
    line15: line,
    description: (
      <>
        Never miss a beat. Send <span className={styles.change}>timely</span>{" "}
        action-based <span className={styles.change}>emails</span> & let your
        users complete their transactions without a hitch.
      </>
    ),
    action: `Set up first transactional email `,
    vector,
  },
  {
    icon: campaign,
    tagline: "Campaigns",
    line15: line,
    description: (
      <>
        Lesser hassle, greater impact. Send timely &{" "}
        <span className={styles.change}>personalized emails</span> about your
        products and services to a wider audience in a few clicks, and meet them
        where they are - <span className={styles.change}>96%</span> of the time
        in their <span className={styles.change}>inboxes</span>.
      </>
    ),
    action: `Create a campaign`,
    vector,
  },
  {
    icon: payment,
    tagline: "Pay as you go",
    line15: line,
    description: (
      <>
        More results, less costs. Tired of spending more than you use? Here,{" "}
        <span className={styles.change}>no subscription</span> fees and you only
        pay as you use and for what you use.
      </>
    ),
    action: `Begin now`,
    vector,
  },
  {
    icon: insight,
    tagline: "Useful Insights",
    line15: line,
    description: (
      <>
        Get insights on customer behavior and interests for better and informed
        decision making.
      </>
    ),
    action: `Learn more`,
    vector,
  },
  {
    icon: integration,
    tagline: "Integration",
    line15: line,
    description: (
      <>
        Connect your web and mobile{" "}
        <span className={styles.change}>applications</span> to Go-Mailer via our{" "}
        <span className={styles.change}>seamless</span> API{" "}
        <span className={styles.change}>integrations</span>, which allow you to
        manage contacts effectively, trigger transactionals, and much more.
      </>
    ),
    action: `Take a sneak peak`,
    vector,
  },
  {
    icon: segmentation,
    tagline: "Audience Segmentation",
    line15: line,
    description: (
      <>
        Identify your customers’{" "}
        <span className={styles.change}>interests</span> and improve campaign
        success by communicating to{" "}
        <span className={styles.change}>targeted</span> audiences. Create as
        many audiences as your business needs to{" "}
        <span className={styles.change}>grow</span> , at no extra cost.
      </>
    ),
    action: `Get started`,
    vector,
  },
];
const handleSignUpAction = () => {
  window.location = `${config.app_url}/register`;
};
export const Features = () => {
  return (
    <section id="features" className={styles.features}>
      <div className={generalStyle.gmContainer}>
        <div className={styles.wrapper}>
          <div className={styles.featureDescription}>
            <div>
              <h2>
                Nurture your brand. Achieve more with{" "}
                <span className={styles.orange}>Go-Mailer.</span>
              </h2>
              <p>
                Promote and grow your business with a Growth-oriented email
                marketing platform that guarantees flexibility, scalability and
                useful insights for growth tracking. Go-Mailer was designed to
                help you attain your growth goals and is with you all the way.
              </p>
              <CTAButton text="Get Started" className={styles.featuresCTA} />
            </div>
            <div className={styles.featureImg}>
              <img src={feature} alt="Features Illustration" />
            </div>
          </div>
          <div className={styles.featureList}>
            {features.map((feature) => (
              <Feature
                key={feature.tagline}
                {...feature}
                action={
                  <a
                    href={`${config.app_url}/register`}
                    onClick={handleSignUpAction}
                  >
                    {feature.action}
                  </a>
                }
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

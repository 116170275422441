import React from 'react';
import cx from 'classnames';
import config from '.config';
import styles from './button.module.css';

export const Button = ({
    text = 'Button',
    className = '',
    onClick = () => { }
}) => {
    return <button
        className={cx(styles.button, className)}
        onClick={onClick}
    >
        {text}
    </button>
}

export const CTAButton = ({
    text = 'Get Started Today',
    className = "",
}) => {
    const handleCTAClick = () => {
        window.location = `${config.app_url}/register`;
    }

    return <Button text={text} className={cx(styles.cta, className)} onClick={handleCTAClick}/>
}
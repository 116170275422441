import React from "react";
import { App } from "app";
import ReactDOM from "react-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import reportWebVitals from "./reportWebVitals";
import "assets/css/colors.css";
import "assets/css/font.css";
import "assets/css/global.css";

const { REACT_APP_ENV } = process.env;
ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet>
        {REACT_APP_ENV !== "production" && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <Helmet>
        {REACT_APP_ENV === "production" && (
          <>
            <title>Go-mailer - a simple email marketing platform</title>
            <meta
              name="description"
              content="Go-Mailer is the email marketing platform that enables you to create, automate, and send marketing campaigns and transactional emails, with guaranteed inbox delivery."
            />
            <meta
              name="keywords"
              content="best email marketing platform, email marketing platform, Go-Mailer, simple email marketing platform, Campaign, transactional email, marketing email"
            />
            <meta
              property="og:title"
              content="Go-Mailer - the best email marketing platform with marketing ROI"
            />
            <meta property="og:type" content="website"></meta>
            <meta
              property="og:image"
              content="https://i.imgur.com/UbVWhrv.jpg"
            />
            <meta property="og:site_name" content="Go-Mailer"></meta>
            <meta property="og:url" content="https://go-mailer.com"></meta>
            <meta
              property="og:description"
              content="The email marketing platform for you to create campaigns, send transactionals & automate marketing emails, & get insights to track growth"
            />
            <meta
              property="og:keywords"
              content="best email marketing platform, email marketing platform, Go-Mailer, marketing ROI, Campaign, transactional email, marketing email"
            />
            <meta property="twitter:card" content="summary_large_image"></meta>
            <meta property="twitter:site" content="@gomailer_ltd"></meta>
            <meta
              property="twitter:title"
              content="Go-Mailer - the best email marketing platform with marketing ROI"
            ></meta>
            <meta
              property="twitter:description"
              content="Go-Mailer is the email marketing platform that enables you to create, automate, and send marketing campaigns and transactional emails, with guaranteed inbox delivery."
            ></meta>
            <meta
              property="twitter:image"
              content="https://i.imgur.com/UbVWhrv.jpg"
            ></meta>
            <meta property="fb:pages" content="1720274894923740"></meta>
            <meta property="twitter:card" content="summary"></meta>
            <link rel="canonical" href="https://go-mailer.com"></link>
          </>
        )}
      </Helmet>
      <App />
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { CTAButton } from "elements/buttons";
import React from "react";
import amplify from "assets/svg/amplify.svg";
import styles from "./main.module.css";
import generalStyle from "app.module.css";

export const Main = () => {
  return (
    <main className={styles.main}>
      <div className={`${generalStyle.gmContainer} ${styles.mainWrapper}`}>
        <div>
          <img
            src={amplify}
            alt="Amplify your voice icon"
            className={styles.mainAmpVoice}
          />
        </div>

        <div className={styles.mainText}>
          <div>
            <h1>Amplify Your Voice.</h1>
            <h1>
              Grow With <span className={styles.name}>Go-Mailer</span>
              <span className={styles.stud}>.</span>
            </h1>
          </div>
          <div className={styles.lorem}>
            ROI seems unachievable but is possible with Go-Mailer - an
            intuitive, simple, yet powerful email marketing platform that
            ensures your brand messages reach your clientele and prospects in
            their <span className={styles.kobo}>Inbox</span>, on time, and at
            the most affordable rates -{" "}
            <span className={styles.kobo}>80 Kobo per email </span> or{" "}
            <span className={styles.kobo}>less</span>.
            <div className={styles.textDiv}>
              Go-Mailer is not just another Email marketing platform but one
              that has been readily shaped to help you meet your business needs.{" "}
            </div>
          </div>
          <CTAButton text="Get Started" className={styles.mainCTA} />
        </div>
      </div>
    </main>
  );
};

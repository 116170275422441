import React from 'react';
import { icons } from './definitions';

export const SiteIcon = ({ negative, height = 325, width = 525 }) => {
  const stroke = negative ? 'white' : '#2B99FF';
  const path_fill = negative ? 'white' : '#FFAA2B';
  return (
    <svg
      width={width}
      height={height}
      viewBox={'0 0 525 325'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="106.25"
        y1="143.75"
        x2="106.25"
        y2="181.25"
        stroke={stroke}
        strokeWidth="37.5"
        strokeLinecap="round"
      />
      <line
        x1="181.25"
        y1="106.25"
        x2="181.25"
        y2="218.75"
        stroke={stroke}
        strokeWidth="37.5"
        strokeLinecap="round"
      />
      <line
        x1="256.25"
        y1="68.75"
        x2="256.25"
        y2="256.25"
        stroke={stroke}
        strokeWidth="37.5"
        strokeLinecap="round"
      />
      <path
        d="M312.499 62.4967C312.499 50 312.499 50 326.785 50C333.928 50 425 66.7317 425 162.5C425 256.25 335.01 275 326.785 275C310.334 275 312.499 275 312.499 262.5V62.5V62.4967Z"
        fill={path_fill}
      />
    </svg>
  );
};

export const Icon = ({ name = '', width = 24, height = 24, onClick = () => {} }) => {
  const icon = icons[name] || icons.add;
  return <span onClick={onClick}>{icon(width, height)}</span>;
};

export const documentationData = [
    {
        id: "introduction",
        className: "noUnderline",
        heading: [
            {
                title: "Introduction",
                content: [
                    "The Go-Mailer API is structured around REST. Our APIs are designed to enable our users to manage important resources within their accounts. The APIs are designed to possess predictable URL structures and to support the most common HTTP verbs and error codes.",
                ],
                code: "curl https://api.go-mailer.com/<endpoint-path>",
            },
        ],
    },
    {
        id: "authentication",
        className: "noUnderline",
        heading: [
            {
                title: "Authentication",
                content: [
                    "The Go-Mailer API uses API Keys to authenticate all requests. Your keys are accessible under Security settings in your account.",
                    "Your API Key possesses elevated privileges within your account; please keep it safe and secure. Do not share your key in publicly accessible areas.",
                    "HTTP requests are authenticated using Bearer Auth. Use -H “Authorization: Bearer RANDOM_KEY==” when making your requests.",
                ],
                code: "POST https://api.go-mailer.com/<endpoint-path> -H “Authorization: Bearer <API_KEY>“",
                subtitle: true,
            },
        ],
    },
    {
        id: "responses",
        content: {
            title: "Responses",
            data: [
                {
                    title: "error",
                    type: "string | optional",
                    text: "This is an optional message indicating any errors that occurred during the processing of the request. If no error occurs, this value is null",
                },
                {
                    title: "payload",
                    type: "Object | optional",
                    text: "This is an optional key -> value set of information returned with successful responses. This property value is `null` when an error occurs whilst processing the request.",
                },
                {
                    title: "payload.data",
                    type: "Resource | Resource[]",
                    text: "This contains the actual Object or a list of Objects for which the request is made",
                },
                {
                    title: "payload.meta.size",
                    type: "number",
                    text: "This returns a count of the instances of the Resource for which the request is made.",
                },
            ],
        },
        responseCode: {
            title: "HTTP RESPONSE CODES",
            data: [
                {
                    status: "200 - OK",
                    meaning: "Everything worked as expected.",
                },
                {
                    status: "400 - Bad Request",
                    meaning: "The request was unacceptable, often due to missing a required parameter.",
                },
                {
                    status: "401 - Unauthorized",
                    meaning: "No valid API key provided.",
                },
                {
                    status: "404 - Not Found",
                    meaning: "The requested resource doesn’t exist.",
                },
                {
                    status: "500 - Server Errors",
                    meaning: "Something went wrong on the Go-Mailer servers",
                },
            ],
        },
    },
    {
        id: "automation",
        heading: [
            {
                title: "Automation Event",
                content: [
                    "This is an object which represents a custom automation event on Go-Mailer. These events are only created by you and they can be triggered from your applications.",
                ],
                code: "curl https://automata.go-mailer.com",
            },
        ],
        content: {
            title: "Attributes",
            data: [
                {
                    title: "name",
                    type: "string",
                    text: "a human readable identifier for the event.",
                },
                {
                    title: "code",
                    type: "string",
                    text: "a unique code for the event. Only letters and underscores are allowed. Event codes MUST NOT begin with the underscore character.",
                },
                {
                    title: "type",
                    type: "enum | optional",
                    text: "The event type. Possible values include: system, custom.",
                },
                {
                    title: "is_doorway",
                    type: "boolean | optional",
                    text: "This is a flag that specifies if the event is a doorway event to another event. If true, this would prevent the journey from proceeding until a configured child event is triggered.",
                },
                {
                    title: "in_use",
                    type: "boolean | optional",
                    text: "a boolean flag that indicates whether an event has been used. Once an event is in use, its code CANNOT be modified.",
                },
            ],
        },
    },
    {
        id: "#",
        heading: [
            {
                title: "Trigger an automation event",
                content: ["Triggers all automation with the specified event code."],
                code: "POST https://automata.go-mailer.com/api/v1/events",
            },
        ],
        content: {
            title: "Parameters",
            data: [
                {
                    title: "event_code",
                    type: "string",
                    text: "The unique event code specified whilst creating the event. This value can be gotten from your account under ‘Automation event’ settings.",
                },
                {
                    title: "context",
                    type: "Object",
                    text: "This is a key -> value data set which is expected to contain extra information related to the event and target for whom the event was triggered.",
                },
                {
                    title: "contact_email",
                    type: "string",
                    text: "The email address of the target for whom the event was triggered.",
                },
            ],
        },
        responseContent: {},
    },
    {
        id: "contact",
        heading: [
            {
                title: "Contacts",
                content: [
                    "The Contact object represents an individual contact in your Go-Mailer account. Go-Mailer provides you with programmatic access to create and modify the contacts in your account.",
                ],
                code: "POST https://users.go-mailer.com/api/contacts",
            },
        ],
        content: {
            title: "Attributes",
            data: [
                {
                    title: "firstname",
                    type: "string | optional",
                    text: "the contact’s first name.",
                },
                {
                    title: "lastname",
                    type: "string | optional",
                    text: "the contact’s last name.",
                },
                {
                    title: "email",
                    type: "string",
                    text: "the contact’s email address. This is unique to the tenant. The same tenant cannot have two contacts with the same email address, regardless of the letter case.",
                },
                {
                    title: "gender",
                    type: "enum | optional",
                    text: "The contact’s gender. Possible values include: male, female, other.",
                },
                {
                    title: "date_of_birth",
                    type: "date | optional",
                    text: "the contact’s date of birth. YYYY-mm-dd",
                },
                {
                    title: "address",
                    type: "object | optional",
                    text: "the contact’s address",
                },
                {
                    title: "address.street",
                    type: "string | optional",
                    text: "the contact’s street address",
                },
                {
                    title: "address.state",
                    type: "string | optional",
                    text: "the contact’s state address",
                },
                {
                    title: "address.country",
                    type: "enum | optional",
                    text: "the contact’s country address",
                },
                {
                    title: "extra_date",
                    type: "Object | optional",
                    text: "This represents any extra information that the tenant would like to store about the contact. This is usually any data that exists in an imported file.",
                },
                {
                    title: "source",
                    type: "enum | optional",
                    text: "This reflects the source from which the contact was created",
                },
            ],
        },
        responseContent: {},
    },
    {
        id: "#",
        heading: [
            {
                title: "Create Contact",
                content: [
                    "This allows you to create and modify a contact in your Go-Mailer account. A new contact if the email address is not in use. Otherwise, the existing contact is updated.",
                ],
                code: "POST https://users.go-mailer.com/api/contacts",
            },
        ],
        content: {
            title: "Parameters",
            data: [
                {
                    title: "firstname",
                    type: "string | optional",
                    text: "the contact’s first name.",
                },
                {
                    title: "lastname",
                    type: "string | optional",
                    text: "the contact’s last name.",
                },
                {
                    title: "email",
                    type: "string",
                    text: "the contact’s email address. This is unique to the tenant. The same tenant cannot have two contacts with the same email address, regardless of the letter case.",
                },
                {
                    title: "gender",
                    type: "enum | optional",
                    text: "The contact’s gender. Possible values include: male, female, other.",
                },
                {
                    title: "date_of_birth",
                    type: "date | optional",
                    text: "the contact’s date of birth. YYYY-mm-dd",
                },
                {
                    title: "address",
                    type: "object | optional",
                    text: "the contact’s address",
                },
                {
                    title: "address.street",
                    type: "string | optional",
                    text: "the contact’s street address",
                },
                {
                    title: "address.state",
                    type: "string | optional",
                    text: "the contact’s state address",
                },
                {
                    title: "address.country",
                    type: "enum | optional",
                    text: "the contact’s country address",
                },
                {
                    title: "extra_date",
                    type: "Object | optional",
                    text: "This represents any extra information that the tenant would like to store about the contact. This is usually any data that exists in an imported file.",
                },
                {
                    title: "source",
                    type: "enum | optional",
                    text: "This reflects the source from which the contact was created",
                },
            ],
        },
        responseContent: {
            text: "The created or updated contact information is returned upon successful processing. An error response is returned otherwise.",
        },
    },
    {
        id: "transactional",
        heading: [
            {
                title: "Transactional Emails",
                content: [
                    "The Transactional Email Object represents a transactional email template in your Go-Mailer account. The API allows you to send or dispatch transactionals to your contacts.",
                ],
                code: "POST https://mailing.go-mailer.com/api/v1/transactionals/dispatch",
            },
        ],
        content: {
            title: "Attributes",
            data: [
                {
                    title: "name",
                    type: "string",
                    text: "A descriptive name for the transactional email.",
                },
                {
                    title: "code",
                    type: "string",
                    text: "A unique code used to identify the transactional. Only alphabets and the underscore character are allowed.",
                },
                {
                    title: "subject",
                    type: "string",
                    text: "The email’s subject as it should be displayed in the user’s mailbox.",
                },
                {
                    title: "sender_name",
                    type: "string",
                    text: "The name to be displayed in the email’s ‘From’ field.",
                },
                {
                    title: "sender_email",
                    type: "string",
                    text: "The email address to be displayed in the email’s ‘From’ field.",
                },
                {
                    title: "body",
                    type: "string",
                    text: "The body of the transactional",
                },
            ],
        },
    },
    {
        id: "#",
        heading: [
            {
                title: "Dispatch a transactional email",
                content: ["Sends the specified transactional email to the specified recipients. A recipient does not have to be a contact in your account."],
                code: "PUT - https://mailing.go-mailer.com/api/v1/transactionals/dispatch",
            },
        ],
        content: {
            title: "Parameters",
            data: [
                {
                    title: "template_code",
                    type: "string",
                    text: "The transactional email’s unique code.",
                },
                {
                    title: "recipient_email",
                    type: "string",
                    text: "The email address of the recipient.",
                },
                {
                    title: "bcc",
                    type: "string",
                    text: "The email address to blind copy.",
                },
                {
                    title: "data",
                    type: "Object",
                    text: "A key -> value set of information which will be used to replaced any placeholders specified in the transactional email.",
                },
                {
                    title: "html",
                    type: "string | optional",
                    text: "A valid HTML document. For the event where you would like to use your own predesigned or pregenerated HTML markup as the email content.",
                },
                {
                    title: "attachments",
                    type: "object[] - optional",
                    text: "This is a list of attachments to be sent via the transactional. Each attachment MUST NOT be more than 1MB.",
                },
                {
                    title: "attachment.filename",
                    type: "string",
                    text: "This is the name of the attachment as it should be displayed to the recipient.",
                },
                {
                    title: "attachment.path",
                    type: "string | optional",
                    text: "The link to the file if the attachment is hosted on a remote server.",
                },
                {
                    title: "attachment.content",
                    type: "string | optional",
                    text: "This is a base64 representation of the attachment. This is best where the attachment is not hosted remotely.",
                },
                {
                    title: "attachment.contentType",
                    type: "enum",
                    text: "This is the mimetype of the attachment. The supported values include: application/pdf.",
                },
                {
                    title: "attachment.encoding",
                    type: "enum",
                    text: "This is the attachment’s content encoding. This should be ‘base64’",
                },
            ],
        },
        responseContent: {
            text: "A successful response is returned if the email was sent successfully. Otherwise, an error response is returned.",
        },
    },
    {
        id: "wallet",
        heading: [
            {
                title: "Wallets",
                content: ["The Wallet Object represents your account’s wallets. This provides you with a financial view of your system."],
                code: "GET https://sales.go-mailer.com/api/v1/wallets",
            },
        ],
        content: {
            title: "Parameters",
            data: [
                {
                    title: "balance",
                    type: "number",
                    text: "This is the wallet’s balance at any given point in time.",
                },
                {
                    title: "rate",
                    type: "number",
                    text: "The rate at which you are charged per 1000 campaign emails sent.",
                },
                {
                    title: "transactional_rate",
                    type: "number",
                    text: "The rate at which you are charged per 1000 transactional emails sent",
                },
            ],
        },
    },
];

export const navLinks = [
    {
        title: "Introduction",
        link: "#introduction",
    },
    {
        title: "Authentication",
        link: "#authentication",
    },
    {
        title: "Responses",
        link: "#responses",
    },
    {
        title: "Automations",
        link: "#automation",
    },
    {
        title: "Contacts",
        link: "#contact",
    },
    {
        title: "Transactional Emails",
        link: "#transactional",
    },
    {
        title: "Wallets",
        link: "#wallet",
    },
];

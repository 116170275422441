import React, { useCallback, useEffect, useState } from "react";
import cx from "classnames";
import * as userService from "services/user";
import { Spinner } from "elements/spinner";
import styles from "./contact.module.css";
import telephone from "assets/svg/telephone.svg";
import generalStyle from "app.module.css";
import { isEmailValid } from "components/shared/utils/general";

const FormMessage = ({ message = "", type = "error" }) => {
  const [formMessage, setFormMessage] = useState("");
  const style = {
    backgroundColor: type === "error" ? "var(--danger)" : "var(--success)",
    color: "var(--neutral-light)",
  };

  useEffect(() => {
    setFormMessage(message);
  }, [message]);

  return (
    formMessage && (
      <div className={styles.alert} style={style}>
        <span>{message}</span>
        <span className={styles.closeIcon} onClick={() => setFormMessage("")}>
          &times;
        </span>
      </div>
    )
  );
};

export const ContactSection = (props) => {
  const [mailResponse, setMailResponse] = useState("");
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [sending, setSending] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [errors, setErrors] = useState({
    fullname: "",
    email: "",
    subject: "",
    body: "",
  });

  const validateField = useCallback((fieldName, value) => {
    let errorMessage = "";
    switch (fieldName) {
      case "fullname":
        if (!value.trim()) {
          errorMessage = "Full name is required.";
        }
        break;
      case "subject":
        if (!value.trim()) {
          errorMessage = "Subject of message is required.";
        }
        break;
      case "body":
        if (!value.trim()) {
          errorMessage = "Body of message is required.";
        }
        break;
      case "email":
        if (!value.trim()) {
          errorMessage = "Email address is required.";
        } else if (!isEmailValid(value)) {
          errorMessage = "Invalid email address.";
        }
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: errorMessage }));
  }, []);

  const doesFormHasError = (errors, values) => {
    const hasErrors = Object.values(errors).some((error) => error !== "");
    const isFieldsIncomplete = Object.values(values).some(
      (value) => !value.trim()
    );
    return hasErrors || isFieldsIncomplete;
  };

  const shouldDisableForm = doesFormHasError(errors, {
    fullname,
    email,
    subject,
    body,
  });

  const clearForm = () => {
    setFullname("");
    setEmail("");
    setSubject("");
    setBody("");
  };
  const handleCheckboxChange = () => {
    setIsChecked((prevChecked) => !prevChecked);
  };

  const submit = () => {
    if (shouldDisableForm) {
      validateField("fullname", fullname);
      validateField("email", email);
      validateField("subject", subject);
      validateField("body", body);
      return;
    }

    const data = {
      fullname,
      email,
      subject,
      body,
    };

    setSending(true);
    userService
      .contactUs(data)
      .then((response) => {
        const {
          data: { error, payload },
        } = response;
        if (error) {
          setMailResponse(() => ({
            message: error,
          }));
          return;
        }

        setMailResponse(() => ({
          message: payload,
          type: "success",
        }));
        clearForm();
        setSending(false);
      })
      .catch(() => {
        setMailResponse(() => ({
          message: "Oops! An error occurred. Please try again.",
        }));
      })
      .finally(() => setSending(false));
  };

  const handleChange = (setState) => (event) => {
    const { name, value } = event.target;
    setState(value);
    validateField(name, value);
  };

  return (
    <div id="contact-us" className={styles.wrapper}>
      <div className={generalStyle.gmContainer}>
        <div className={styles.formWrapper}>
          <div className={styles.mainForm}>
            <h2>Got Questions?</h2>
            <p>
              Let's get this conversation started. Fill out the form below, and
              we will be in touch.
            </p>

            <FormMessage {...mailResponse} />
            <div className={styles.contactForm}>
              <div className={styles.formGrid2Cols}>
                <div className={styles.formCol}>
                  <label>FULL NAME</label>
                  <input
                    type="text"
                    name="fullname"
                    className={styles.input}
                    placeholder="Brooklyn Simmons"
                    onInput={handleChange(setFullname)}
                    value={fullname}
                  />
                  <span class={styles.error}>{errors.fullname}</span>
                </div>
                <div className={styles.formCol}>
                  <label>EMAIL ADDRESS</label>
                  <input
                    type="email"
                    name="email"
                    className={styles.input}
                    placeholder="kenzi.lawson@example.com"
                    onInput={handleChange(setEmail)}
                    value={email}
                  />
                  <span class={styles.error}>{errors.email}</span>
                </div>
              </div>

              <div className={styles.formCol}>
                <label>SUBJECT</label>
                <input
                  type="text"
                  name="subject"
                  className={styles.input}
                  placeholder="Planning for a Crisis"
                  onInput={handleChange(setSubject)}
                  value={subject}
                />
                <span class={styles.error}>{errors.subject}</span>
              </div>

              <div className={styles.formCol}>
                <label>MESSAGE</label>
                <div className={cx(styles.formCol, styles.col2)}>
                  <textarea
                    name="body"
                    className={styles.textarea}
                    placeholder="Write your message here..."
                    onInput={handleChange(setBody)}
                    value={body}
                  ></textarea>
                </div>
                <span class={styles.error}>{errors.body}</span>
              </div>
            </div>

            <div>
              <div className={styles.news}>
                <label className={styles.checkBox}>
                  <input
                    type="checkbox"
                    style={{ display: "none" }} // Hide the default checkbox input
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <div
                    className={`${styles.customCheckbox} ${
                      isChecked ? "checked" : " "
                    }`}
                  >
                    {isChecked && (
                      <span className={styles.checkmark}>&#x2713;</span>
                    )}
                  </div>
                  Sign me up for the{" "}
                  <span className={styles.sitename}>
                    Go-Mailer<span className={styles.stub}>. </span>
                  </span>
                  newsletter
                </label>
              </div>
              <button
                className={styles.button}
                onClick={submit}
                disabled={sending}
              >
                {sending && (
                  <span className={styles.spinner}>
                    <Spinner />
                  </span>
                )}
                <span className={styles.send}>Send Message</span>
              </button>
            </div>
          </div>
          <div>
            <img
              src={telephone}
              alt="phone icon"
              className={styles.telephone}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

import React from "react";
import { Link } from "react-router-dom";
import logo from "assets/svg/logo.svg";
import phone from "assets/icons/phone.svg";
import mail from "assets/icons/mail.svg";
import address from "assets/icons/address.svg";
import twitter from "assets/svg/icons-twitter.svg";
import instagram from "assets/svg/icons-instagram.svg";
import facebook from "assets/svg/icons-facebook.svg";
import linkedin from "assets/svg/icons-linkedin.svg";
import styles from "./footer.module.css";
import generalStyle from "app.module.css";

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={generalStyle.gmContainer}>
        <div className={styles.detail}>
          <div className={styles.info}>
            <div className={styles.siteInfo}>
              <img
                alt="Go-Mailer Icon"
                src={logo}
                className={styles.siteIcon}
              />
              <span>Go-Mailer. </span>
            </div>
            <div className={styles.contact}>
              <div className={styles.location}>
                <img
                  src={address}
                  alt="Location Icon"
                  className={styles.locationIcon}
                />
                <span className={styles.phoneText}>
                  {" "}
                  34, Fola-Bolumole Street, Ring Road, Ibadan, Oyo State,
                  Nigeria.
                </span>
              </div>
              <div className={styles.mail}>
                <img src={mail} alt="Mail Icon" className={styles.mailIcon} />
                <span className={styles.mailText}>
                  {" "}
                  <a href="mailto:info@go-mailer.com">info@go-mailer.com</a>
                </span>
              </div>
              <div className={styles.call}>
                <img
                  src={phone}
                  alt="Phone Icon"
                  className={styles.phoneIcon}
                />
                <span className={styles.phoneText}>
                  {" "}
                  <a href="tel:+2349037066614">+2349037066614 </a>
                </span>
              </div>
            </div>
          </div>
          <div className={styles.footerColumn}>
            <div className={styles.footerCol}>
              <h4 className={styles.colHead}>Product</h4>
              <a href=".">Email Marketing</a>
              <a href="#about-us">Features</a>
              <a href="https://go-mailer.blog">Blog</a>
            </div>
            <div className={styles.footerCol}>
              <h4 className={styles.colHead}>Company</h4>
              <a href="#pricing">Pricing</a>
              <a href="#contact-us">Contact Us</a>
              {/* <a href="#">Careers</a> */}
            </div>
            <div className={styles.footerCol}>
              <h4 className={styles.colHead}>Legal</h4>
              <Link to="/terms">Terms of Use </Link>
              <Link to="/privacy">Privacy Policy</Link>
            </div>
          </div>
        </div>

        <hr className={styles.line} />

        <div className={styles.footnote}>
          <div className={styles.socialIcons}>
            <a href="https://www.linkedin.com/company/go-mailer/">
              <img alt="LinkedIn Icon" src={linkedin} />
            </a>
            <a href="https://www.instagram.com/gomailer_ltd/">
              <img alt="Instagram Icon" src={instagram} />
            </a>
            <a href="https://www.facebook.com/gomailer_ltd/">
              <img alt="Instagram Icon" src={facebook} />
            </a>
            <a href="https://twitter.com/gomailer_ltd">
              <img alt="Twitter Icon" src={twitter} />
            </a>
          </div>
          <span className={styles.copyright}>
            copyright &copy; {new Date().getFullYear()} Go-Mailer Limited.
          </span>
        </div>
      </div>
    </footer>
  );
};

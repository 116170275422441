import React, { useState, useCallback } from "react";
import styles from "./opt-in.module.css";
import { Icon } from "assets/icons";
import { Spinner } from "elements/spinner";
import ebook from "assets/svg/ebook.svg";
import * as userService from "services/user";
import { Alert } from "components/shared/form-alert";
import { isEmailValid } from "components/shared/utils/general";

export const OptInForm = ({ onFormSubmit, onClose }) => {
  const [firstname, setFirstname] = useState("");
  const [email, setEmail] = useState("");
  const [sending, setSending] = useState(false);
  const [alert, setAlert] = useState({ message: "" });

  const [errors, setErrors] = useState({
    firstname: "",
    email: "",
  });

  const validateField = useCallback((fieldName, value) => {
    let errorMessage = "";
    switch (fieldName) {
      case "firstname":
        if (!value.trim()) {
          errorMessage = "First name is required.";
        }
        break;
      case "email":
        if (!value.trim()) {
          errorMessage = "Email address is required.";
        } else if (!isEmailValid(value)) {
          errorMessage = "Invalid email address.";
        }
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: errorMessage }));
  }, []);

  const handleChange = (setState) => (event) => {
    const { name, value } = event.target;
    setState(value);
    validateField(name, value);
  };

  const isFormDisabled = (errors, values) => {
    const hasErrors = Object.values(errors).some((error) => error !== "");
    const isFieldsIncomplete = Object.values(values).some(
      (value) => !value.trim()
    );
    return hasErrors || isFieldsIncomplete;
  };

  const disableSubmitButton = isFormDisabled(errors, {
    firstname,
    email,
  });

  const handleSubmit = () => {
    if (disableSubmitButton) {
      validateField("firstname", firstname);
      validateField("email", email);
      return;
    }

    setSending(true);
    userService
      .create_record_from_webform(email, firstname)
      .then((response) => {
        const {
          data: { error },
        } = response;
        if (error) {
          return setAlert({ message: error });
        }
        onFormSubmit();
      })
      .catch(() => {
        setAlert({ message: "An error occurred while submitting the form." });
      })
      .finally(() => setSending(false));
  };

  return (
    <div className={`${styles.formWrapper} `}>
      <div className={styles.alert}>
        <Alert {...alert} onClose={() => setAlert({ message: "" })} />
      </div>
      <span className={styles.icon}>
        <Icon
          width={35}
          height={35}
          name="close"
          className={styles.close}
          onClick={onClose}
        />
      </span>
      <div className={styles.content}>
        <img
          src={ebook}
          alt="Email marketing ebook icon"
          className={styles.ebookImg}
        />

        <div className={styles.formText}>
          <div className={styles.text}>
            <h4>
              11 costly mistakes to avoid in Email Marketing:{" "}
              <span className={styles.purpleText}>Get a copy now</span>
            </h4>
            <p>
              Avoid these mistakes and watch your ROI on email marketing
              increase. This E-book gives you perspective on what you may have
              been doing wrong, what to avoid and what should be done instead.
            </p>
          </div>
          <div className={styles.inputForm}>
            <div className={styles.formCol}>
              <input
                type="text"
                name="firstname"
                className={styles.input}
                placeholder="First name"
                onInput={handleChange(setFirstname)}
                value={firstname}
                required
              />
              <small class={styles.error}>{errors.firstname}</small>
            </div>
            <div className={styles.formCol}>
              <input
                type="email"
                name="email"
                className={styles.input}
                placeholder="Email address"
                onInput={handleChange(setEmail)}
                value={email}
                required
              />
              <span className={styles.error}>{errors.email}</span>
            </div>
          </div>
          <div className={styles.terms}>
            By clicking "<span className={styles.copy}>Get a copy now</span>
            ", you agree to receiving newsletters from{" "}
            <span className={styles.sitename}>
              Go-Mailer<span className={styles.dot}>.</span>
            </span>
          </div>
          <button
            className={styles.button}
            onClick={handleSubmit}
            disabled={sending}
          >
            {sending && (
              <span className={styles.spinner}>
                <Spinner />
              </span>
            )}
            <span className={styles.send}> Get a copy now </span>
          </button>
        </div>
      </div>
    </div>
  );
};

import React from "react";
import { Icon } from "assets/icons";
import styles from "./success-form.module.css";
import thumbs from "assets/svg/thumbs.svg";

export const SuccessPopup = ({ onClose }) => {
  return (
    <div className={styles.successForm}>
      <span className={styles.icon}>
        <Icon
          width={35}
          height={35}
          name="close"
          className={styles.close}
          onClick={onClose}
        />
      </span>

      <div className={styles.successDiv}>
        <img src={thumbs} alt="Thumbs up icon" className={styles.thumbImg} />
        <div className={styles.successText}>
          <h5>&#x1F44C;&#x1F3FE;You made the right move. Check your email.</h5>
          <p>
            You have received an email with the e-book via the email address you
            provided.{" "}
            <span className={styles.free}>Download your free copy.</span>
          </p>
          <span className={styles.start}>
            Start reading, internalize, and avoid those mistakes &#x1F609;
          </span>
        </div>
      </div>
    </div>
  );
};

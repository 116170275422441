import React from "react";
import styles from "./docs.module.css";
import generalStyle from "app.module.css";
import Header from "./header";
import { documentationData, navLinks } from "./data";
import cx from "classnames";

const CodeWrapper = ({ children }) => (
    <div className={styles.codeWrapper}>
        <code>{children}</code>
    </div>
);

const MainSection = ({ title, content, code, subtitle = false }) => {
    return (
        <div>
            {!subtitle ? <h2>{title}</h2> : <h3>{title}</h3>}
            {content.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
            ))}

            <CodeWrapper>{code}</CodeWrapper>
        </div>
    );
};

const SectionContent = ({ title, content }) => (
    <div>
        <h3>{title}</h3>
        {content.map(({ title, type, text }, index) => (
            <div>
                <h5>
                    <span className={styles.boldText}>{title}</span>
                    <span className={styles.mutedText}>{type}</span>
                </h5>
                <p>{text}</p>
            </div>
        ))}
    </div>
);

const ResponseContent = ({ text = "Standard Response" }) => (
    <div>
        <h3>Response</h3>
        <p>{text}</p>
    </div>
);

const ResponseCodeContent = ({ title, content }) => (
    <div className={styles.responseCodes} id="#responses">
        <div className={styles.header}>
            <h5>{title}</h5>
        </div>
        <div className={styles.content}>
            {content.map(({ status, meaning }, index) => (
                <div>
                    <h6>{status}</h6>
                    <p>{meaning}</p>
                </div>
            ))}
        </div>
    </div>
);

export default function Documentation() {
    return (
        <div className={styles.wrapper}>
            <Header />
            <div className={`${styles.layout} ${generalStyle.gmContainer}`}>
                <div className={styles.title}>
                    {navLinks.map((data, index) => (
                        <a key={index} href={data.link}>
                            {data.title}
                        </a>
                    ))}
                </div>

                <div className={styles.contentWrapper}>
                    {documentationData.map((section, index) => (
                        <div className={cx(styles.content, section.className ? styles[section.className] : "")} id={section.id} key={section.id + index}>
                            {section.heading &&
                                section.heading.map((heading, index) => (
                                    <MainSection title={heading.title} content={heading.content} code={heading.code} subtitle={heading.subtitle} />
                                ))}

                            {section.content && <SectionContent title={section.content.title} content={section.content.data} />}

                            {section.responseCode && <ResponseCodeContent title={section.responseCode.title} content={section.responseCode.data} />}

                            {section.responseContent && <ResponseContent text={section.responseContent?.text} />}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

/** */
import React from "react";
import styles from "./spinner.module.css";

export const Spinner = ({ style = {} }) => {
  return (
    <div className={styles.spinner} style={style}>
      <div className="dualRing">
        <div className={styles.ldioSpin}>
          <div></div>
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

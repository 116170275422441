import cx from "classnames";
import React, { useState, useEffect } from "react";
import styles from "./pricing.module.css";

const currencies = [
  { code: "NGN", label: "NGN" },
  { code: "USD", label: "USD" },
];

const price_scale = [
  { min: 0, max: 499999, rates: { NGN: 1, USD: 0.001 } },
  { min: 500000, max: 999999, rates: { NGN: 0.8, USD: 0.001 } },
  { min: 1000000, max: 2999999, rates: { NGN: 0.7, USD: 0.0007 } },
  { min: 3000000, max: Number.MAX_SAFE_INTEGER, rates: { NGN: 0.65, USD: 0.0007 } },
];

export const Pricing = () => {
  const [currency, setCurrency] = useState("NGN");
  const [price, setPrice] = useState(0);
  const [volume, setVolume] = useState(0);

  useEffect(() => {
    computePrice(volume, currency);
  }, [currency, volume]);

  const computePrice = (volume, currency) => {
    const tier = price_scale.find((scale) => volume >= scale.min && volume <= scale.max);
    const rate = tier.rates[currency];
    setPrice(() => Number((volume * rate).toFixed(2)).toLocaleString());
  };

  return (
    <section id="pricing" className={styles.wrapper}>
      <h1 className={styles.heading}>
        {" "}
        Spend Less<span className={styles.blue}>.</span> Achieve More{" "}
      </h1>
      <p className={styles.subheading}>
        Estimate your monthly cost from your monthly email sends. This is the number of your contacts multiplied by the
        number of campaigns you intend to send each month.
      </p>
      <div className={styles.calculator}>
        <div className={styles.calcWrapper}>
          <div className={styles.formGroup}>
            <label>Est. monthly emails</label>
            <input type="number" onInput={(e) => setVolume(e.target.value)} />
          </div>
          <div className={cx(styles.formGroup, styles.currency)}>
            <label>Currency</label>
            <select onChange={(e) => setCurrency(e.target.value)}>
              {currencies.map((cxy) => (
                <option selected={currency === cxy.code} value={cxy.code}>
                  {cxy.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className={styles.priceDisplay}>
          <div className={styles.priceLabel}>Est. monthly cost ({currency})</div>
          <div className={styles.price}>{price}</div>
        </div>
      </div>
    </section>
  );
};

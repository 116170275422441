import React from 'react'
import styles from './feature.module.css'

export const Feature = ({
  icon,
  tagline = 'Feature title',
  line15,
  description = 'Feature description',
  action = 'Feature action',
  vector,
}) => {
  return (
    <div className={styles.feature}>
      <img src={icon} className={styles.icon} alt={tagline} />
      <div className={styles.fbody}>
        <h3>{tagline}</h3>
        <img alt={tagline} src={line15} className={styles.fline} />
        <p>{description}</p>
        <div className={styles.actionBtn}>
          <span>{action}</span>
          <img alt="btn-right" src={vector} className={styles.vec} />
        </div>
      </div>
    </div>
  )
}

import config from "../.config";
import axios from "axios";

export const contactUs = async (data) => {
  return axios.post(`${config.users_uri}/guests/enquiry`, data);
};

export const unsubscribe = async (
  tracking_id,
  options = {},
  extra_details = {}
) => {
  return axios.put(
    `${config.users_uri}/guests/unsubscribe/${tracking_id}?contact_email=${extra_details.email}&contact_id=${extra_details.id}`,
    { ...options }
  );
};

export const create_record_from_webform = async (
  email,
  firstname
) => {
  return axios.get(
    `${config.users_uri}/api/contacts/${config.api_key}/capture/${config.audience_id}?email=${email}&firstname=${firstname}&source='website-api'`
  );
};

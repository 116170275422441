import React from "react";
import icon1 from "assets/svg/reviews/advantar-icon.svg";
import icon2 from "assets/svg/reviews/grazac-icon.svg";
import icon3 from "assets/svg/reviews/bing-icon.svg";
import advantar from "assets/svg/brands/advantar.svg";
import bing from "assets/svg/brands/bing.svg";
import edves from "assets/svg/brands/edves.svg";
import flow from "assets/svg/brands/flow.svg";
import grazac from "assets/svg/brands/grazac.svg";
import background from "assets/svg/background.svg";
import styles from "./reviews.module.css";
import generalStyle from "app.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Mousewheel } from "swiper";
import "swiper/swiper.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import cx from "classnames";

SwiperCore.use([Navigation, Pagination, Mousewheel]);

const allReviews = [
  {
    title: "Wisdom, CEO of Bingpay",
    company: "Bingpay",
    content:
      "Our experience with Go-Mailer has been astounding! They satisfy our email marketing needs without making us break the bank. The platform is super easy to navigate, and my team can work independently and fast. Really, Go-Mailer is 5-star and I would gladly recommend it.",
    image: icon3,
  },
  {
    title: "Samson, Content Strategist",
    company: "Grazac Technology Ltd.",
    content:
      "Go-Mailer provides a stable and reliable structure for our brand. The currency has been tailored to include our local currency, which makes the platform cheap and easy to use- a huge plus. In addition, the software is easy to navigate, and their customer service is excellent! These incredible features help our business run smoothly, - I highly recommend it.",
    image: icon2,
  },
  {
    title: "Kayode",
    company: "Advantar Marketing",
    content:
      "As a marketing agency, we collect a lot of data for both ourselves and our clients.  When we were looking for a tool to help us effectively manage our email data list, we had to go with Go-Mailer because of the pricing and the excellent customer service. We could literally call anyone on the team, and they are happy to help us solve any issue we’re facing. I’d recommend Go- mailer 100%",
    image: icon1,
  },
  {
    title: "Wisdom, CEO of Bingpay",
    company: "Bingpay",
    content:
      "Our experience with Go-Mailer has been astounding! They satisfy our email marketing needs without making us break the bank. The platform is super easy to navigate, and my team can work independently and fast. Really, Go-Mailer is 5-star and I would gladly recommend it.",
    image: icon3,
  },
  {
    title: "Samson, Content Strategist",
    company: "Grazac Technology Ltd.",
    content:
      "Go-Mailer provides a stable and reliable structure for our brand. The currency has been tailored to include our local currency, which makes the platform cheap and easy to use- a huge plus. In addition, the software is easy to navigate, and their customer service is excellent! These incredible features help our business run smoothly, - I highly recommend it.",
    image: icon2,
  },
  {
    title: "Kayode",
    company: "Advantar Marketing",
    content:
      "As a marketing agency, we collect a lot of data for both ourselves and our clients.  When we were looking for a tool to help us effectively manage our email data list, we had to go with Go-Mailer because of the pricing and the excellent customer service. We could literally call anyone on the team, and they are happy to help us solve any issue we’re facing. I’d recommend Go- mailer 100%",
    image: icon1,
  },
];

const allBrands = [
  {
    title: "Bingpay",
    image: bing,
  },
  {
    title: "Advantar",
    image: advantar,
  },
  {
    title: "Edves",
    image: edves,
  },
  {
    title: "Flowmono",
    image: flow,
  },
  {
    title: "Grazac Academy",
    image: grazac,
  },
];

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1023,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1279,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
  ],
};

export const Reviews = () => {
  return (
    <section className={styles.pricing}>
      <div className={generalStyle.gmContainer}>
        <Slider {...settings} className={styles.slider}>
          {allBrands.map((brand, index) => (
            <div key={index} className={styles.slide}>
              <img
                src={brand.image}
                alt={brand.title}
                className={styles.brand}
              />
            </div>
          ))}
        </Slider>

        <div className={cx(styles.background, generalStyle.gmContainer)}>
          <img
            src={background}
            alt="Website background"
            className={styles.rec}
          />
        </div>
        <div className={styles.reviews}>
          <div className={styles.revContainer}>
            <Swiper
              spaceBetween={40}
              slidesPerView={1}
              initialSlide={3}
              centeredSlides={true}
              breakpoints={{
                1024: {
                  slidesPerView: 3,
                  centeredSlides: false,
                  slideActiveClass: 1,
                },
              }}
              loop
              navigation
              pagination
              mousewheel={{ sensitivity: 0.5 }}
              className={styles.mySwiper}
            >
              {allReviews.map((review, index) => (
                <SwiperSlide key={index}>
                  <div className={styles.review}>
                    <img src={review.image} alt="Icon" className={styles.rev} />
                    <div className={styles.content}>
                      <div className={styles.text}>
                        <h3>{review.title}</h3>
                        <h5>{review.company}</h5>
                        <p>{review.content}</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

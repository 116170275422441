import React, { useEffect, useState } from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import styles from "app.module.css";
import { Header } from "components/header";
import { Main } from "components/main";
import { Features } from "components/features";
import { Pricing } from "components/pricing";
import { Reviews } from "components/reviews";
import { Footer } from "components/footer";
import { PrivacyPolicy } from "components/privacy";
import { TermsAndConditions } from "components/terms";
import { ContactSection } from "components/contact";
import { Unsubscribe } from "components/unsubscribe";
import { OptInForm } from "components/opt-in";
import { SuccessPopup } from "components/success-form";
import Documentation from "components/docs";

const MainLayout = () => (
    <>
        <Main />
        <Features />
        <Pricing />
        <Reviews />
        <ContactSection />
        <Footer />
    </>
);

export const App = () => {
    const [, setShowLogo] = useState(true);
    const [showOptInForm, setShowOptInForm] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);

    console.log({ location: window?.location.pathname });

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowOptInForm(true);
        }, 10000);

        return () => clearTimeout(timer);
    }, []);

    const handleClose = () => {
        setShowOptInForm(false);
        setShowSuccessPopup(false);
    };
    const handleOptInFormSubmit = () => {
        setShowOptInForm(false);
        setShowSuccessPopup(true);

        setTimeout(() => {
            setShowSuccessPopup(false);
        }, 5000);
    };

    return (
        <div className={styles.pageWrapper}>
            {!window?.location?.pathname.startsWith("/docs/api") && <Header />}
            <BrowserRouter>
                <Routes>
                    <Route path="/features" element={<Features />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/contact" element={<ContactSection />} />
                    <Route path="/privacy" element={<PrivacyPolicy />} />
                    <Route path="/terms" element={<TermsAndConditions />} />
                    <Route path="/docs/*" element={<Documentation />} />
                    <Route path="/unsubscription/:tracking_id/:email/:id" element={<Unsubscribe setShowLogo={setShowLogo} />} />
                    <Route path="*" element={<MainLayout />} />
                </Routes>
                <Outlet />
            </BrowserRouter>
            {showOptInForm && (
                <div className={styles.modal_overlay}>
                    <OptInForm onFormSubmit={handleOptInFormSubmit} onClose={handleClose} />
                </div>
            )}
            {showSuccessPopup && (
                <div className={styles.modal_overlay}>
                    <SuccessPopup onClose={handleClose} />
                </div>
            )}
        </div>
    );
};

import logo from "assets/icons/logo-sm.svg";
import styles from "./docs.module.css";
import generalStyles from "app.module.css";

export default function Header() {
    return (
        <div className={`${styles.headerLayout} ${generalStyles.gmContainer}`}>
            <div className={styles.siteInfo}>
                <a href="*" className={styles.siteLink}>
                    <img src={logo} alt="Go-Mailer Icon" className={styles.siteIcon} />
                    <div className={styles.siteName} aria-labelledby="Site Name">
                        Go-Mailer<span className={styles.stub}>.</span>
                    </div>
                </a>
            </div>
            <div className={styles.apiText}>API</div>
        </div>
    );
}

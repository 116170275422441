import React, { useState } from "react";
import logo from "assets/icons/logo-sm.svg";
import styles from "./header.module.css";
import { Button } from "elements/buttons";
import config from ".config.js";
import generalStyle from "app.module.css";
import { Icon } from "@iconify/react";

export const Header = () => {
  const [activeState, setActiveState] = useState(false);

  const handleLoginAction = () => {
    window.location = `${config.app_url}/login`;
  };

  const handleSignUpAction = () => {
    window.location = `${config.app_url}/register`;
  };

  const toggleNavButton = () => {
    setActiveState((previousState) => !previousState);
  };

  const NavLinks = () => (
    <>
      <a href="#features">Features </a>
      <a href="#pricing"> Pricing </a>
      <a href="https://go-mailer.blog"> Blog </a>
      <a href="#contact-us"> Contact Us </a>
    </>
  );

  const NavButtons = () => (
    <>
      <Button
        text="Log In"
        className={styles.loginBtn}
        onClick={handleLoginAction}
      />
      <Button
        text="Sign Up"
        className={styles.signUpBtn}
        onClick={handleSignUpAction}
      />
    </>
  );

  return (
    <header className={`${styles.header} ${generalStyle.gmContainer}`}>
      <div className={styles.navWrapper}>
        <div className={styles.siteInfo}>
          <a href="*" className={styles.siteLink}>
            <img src={logo} alt="Go-Mailer Icon" className={styles.siteIcon} />
            <div className={styles.siteName} aria-labelledby="Site Name">
              Go-Mailer<span className={styles.stub}>.</span>
            </div>
          </a>
        </div>

        <nav className={styles.navBar}>
          <NavLinks />
        </nav>
        <div className={styles.headBtn}>
          <NavButtons />
        </div>
        <div className={styles.options}>
          <Icon
            icon={`lucide:${activeState ? "x" : "menu"}`}
            onClick={toggleNavButton}
          />
        </div>
      </div>
      <div
        className={`${styles.mobileNav} ${
          activeState ? styles.showNavbar : ""
        }`}
      >
        <NavLinks />
        <NavButtons />
      </div>
    </header>
  );
};
